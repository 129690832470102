export const updateObject = (oldObject, updateObject) => {
  return {
    ...oldObject,
    ...updateObject
  };
};

export const arrayUnique = (array) => {
  var a = array.concat();
  for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
              a.splice(j--, 1);
      }
  }

  return a;
}

export const updateObjectProperty = (oldObject, property , value) => {
  return {
    ...oldObject,
    [property]: value
  };
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 3600000,
    // This value represent 60 min
  };
  localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export const setLocal = (key, value) => {
  localStorage.setItem(key, value);
}

export const getLocal = (key) => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return item;
}

export const removeLocal = (key) => {
  localStorage.removeItem(key);
  return null;
}
