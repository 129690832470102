import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { CssBaseline, Box, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import Skeleton from "@material-ui/lab/Skeleton";

import Register from "./pages/auth/register";
import UpdatePassword from "./pages/auth/updatePassword";
import UpdateEmail from "./pages/auth/updateEmail";
import LogIn from "./pages/auth/login";
import Admins from "./pages/dashboard/admins";
import Profile from "./pages/dashboard/profile";
import Users from "./pages/dashboard/users";
import Businesses from "./pages/dashboard/businesses";
import Business from "./pages/dashboard/business";
import User from "./pages/dashboard/user";
import Nodes from "./pages/dashboard/nodes";
import Companies from "./pages/dashboard/companies";
import System from "./pages/dashboard/system";
import BankAccounts from "./pages/dashboard/bankAccounts";
import Notifier from "./components/layout/Notifier";
import Copyright from "./components/layout/Copyright";
import MenuAppBar from "./components/layout/menuAppBar";
import AdminApproval from "./pages/dashboard/adminApproval";

import * as actionCreator from "./store/action";
import { setLocal, getLocal, removeLocal } from "./asset/utility";
import ApprovalFlow from "./pages/dashboard/approvalFlow";
import stocks from "./pages/dashboard/stocks";
import CreateAnnouncement from "./pages/dashboard/CreateAnnouncement";
import UpdateAnnouncement from "./pages/dashboard/UpdateAnnouncement";
import Cryptopia from "./pages/dashboard/cryptopia";
import Stats from "./pages/dashboard/Stats";
import kyc from "./pages/dashboard/kyc";
import ProspectusTool from "./pages/dashboard/tool";

const themeLight = createMuiTheme({
 palette: {
  type: "light",
  background: {
   default: "#fafafa",
  },
 },
});

const themeDark = createMuiTheme({
 palette: {
  type: "dark",
  background: {
   default: "#1A1A1A",
  },
 },
});

const App = (props) => {
 const {
  ResetErrors,
  IsLogInAsync,
  isLoggedIn,
  checking,
  loading,
  notify,
  logout,
  admin,
 } = props;

 const [darkMode, setDarkMode] = useState(
  getLocal("dark") === "false" ? false : true
 );

 useEffect(() => {
  if (!loading && !isLoggedIn) {
   IsLogInAsync();
  }
  return () => {
   ResetErrors();
  };
 }, [ResetErrors, IsLogInAsync]);

 // const theme = createMuiTheme({
 // 	palette: {
 // 		type: darkMode ? "dark" : "light",
 // 	},
 // });

 const changeMode = () => {
  {
   darkMode ? setLocal("dark", !darkMode) : removeLocal("dark");
  }
  setDarkMode(!darkMode);
 };

 // add action to all snackbars
 const notistackRef = createRef();
 const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
 };

 const adminRoutes = (
  <React.Fragment>
   <MenuAppBar darkMode={darkMode} changeMode={changeMode} />
   <Switch>
    {/* <Route exact path="/" component={Home}></Route> */}
    <Route path="/admins" component={Admins}></Route>
    <Route path="/resetpassword/:token" component={UpdatePassword}></Route>
    <Route
     path="/resetemail/:token?/:oldemail?"
     component={UpdateEmail}
    ></Route>
    <Route path="/profile" component={Profile}></Route>
    <Route path="/users" component={Users}></Route>
    <Route path="/businesses" component={Businesses}></Route>
    <Route path="/business/:id/:profileId" component={Business}></Route>
    <Route path="/user/:id" component={User}></Route>
    <Route path="/nodes" component={Nodes}></Route>
    <Route path="/companies" component={Companies}></Route>
    <Route path="/system" exact component={System}></Route>
    <Route path="/bankAccounts" exact component={BankAccounts}></Route>
    <Route
     path="/system/createannouncement"
     component={CreateAnnouncement}
    ></Route>
    <Route
     path="/system/updateannouncement/:id"
     component={UpdateAnnouncement}
    ></Route>
    <Route path="/stocks" component={stocks}></Route>
    <Route path="/stats" component={Stats}></Route>
    <Route path="/approval" component={AdminApproval} exact></Route>
    <Route path="/approval/:approvalId" component={ApprovalFlow} />
    <Route path="/cryptopia" component={Cryptopia} exact></Route>
    <Route path="/kyc" component={kyc} exact></Route>
    <Route path="/tool" component={ProspectusTool} exact></Route>
    {admin ? (
     <>
      <Redirect
       to={admin?.type !== "Content Creator" ? "/users" : "/system"}
       component={admin?.type !== "Content Creator" ? Users : System}
      />
     </>
    ) : null}
   </Switch>
  </React.Fragment>
 );

 const authRoutes = (
  <Switch>
   <Route path="/register/:token" component={Register}></Route>
   <Route path="/resetpassword/:token" component={UpdatePassword}></Route>
   <Route path="/resetemail/:token?/:oldemail?" component={UpdateEmail}></Route>
   <Route path="/login/" component={LogIn}></Route>
   <Redirect to="/login" />
  </Switch>
 );

 return (
  <ThemeProvider theme={darkMode ? themeDark : themeLight}>
   <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
     <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClickDismiss(key)}
     >
      <Close fontSize="small" />
     </IconButton>
    )}
   >
    <CssBaseline />
    <Notifier />
    {checking ? (
     <Skeleton variant="rect" width="100%" height={100} />
    ) : (
     <React.Fragment>{isLoggedIn ? adminRoutes : authRoutes}</React.Fragment>
    )}
    <Box mt={5}>
     <Copyright />
    </Box>
   </SnackbarProvider>
  </ThemeProvider>
 );
};
const mapStateToProps = ({ authReducer, notifyReducer, adminReducer }) => {
 return {
  checking: authReducer.checking,
  loading: authReducer.loading,
  isLoggedIn: authReducer.isLoggedIn,
  errorMessage: authReducer.errorMessage,
  successMessage: authReducer.successMessage,
  notifications: notifyReducer.notifications,
  admin: adminReducer.admin,
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  IsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
  ResetErrors: () => dispatch(actionCreator.ResetErrors()),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
  logout: () => dispatch(actionCreator.OnLogout()),
 };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
