import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import QRComponent from "../../../../components/layout/qrcode";
import clsx from "clsx";
import * as actionCreator from "../../../../store/action/index";
import Styles from "../../../../components/styles";

const CustomInput = withStyles({
    root: {
      //background: "#eeededd7",
      borderRadius: 8,
      border: 0,
      width: "100%",
      fontFamily: "Manrope",
    },
  })((props) => <TextField {...props} />);
  
  

  const ScanQrCode =(props)=>{
    const classes=Styles();
    
    const host = `${process.env.REACT_APP_STATIC_LINK}/ref/`;
    const {user}=props;


    return(
      <React.Fragment>
        <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  className={clsx(classes.root, classes.columnView)}
                  spacing={2}
                  style={{ maxWidth: "100%" }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  >
                    <Paper className={classes.paper} elevation={5}>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          container
                          className={classes.columnView}
                          xs={12}
                          md={4}
                          spacing={1}
                        >
                          <Grid item>
                            <QRComponent />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              align="center"
                              className={classes.yellowText}
                            >
                              <strong>Scan QR Code</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          className={classes.columnViewLeft}
                          xs={12}
                          md={8}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="subtitle2">
                              <strong>Share the below Link</strong>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={
                                user?.nodes[0].referral_code
                                  ? host + user?.nodes[0].referral_code
                                  : null
                              }
                              InputProps={{
                                endAdornment: (
                                  <React.Fragment>
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            host + user?.nodes[0].referral_code
                                          );
                                        }}
                                      >
                                        <FileCopyIcon fontSize="small" />
                                        <Typography>&nbsp;</Typography>
                                        <Typography variant="caption">
                                          Copy Link
                                        </Typography>
                                      </IconButton>
                                    </InputAdornment>
                                  </React.Fragment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item container>
                            <Grid xs={1}>Or</Grid>
                            <Grid xs={11}>
                              <hr color="#FF7300" />
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              <strong>Share the code only</strong>
                            </Typography>
                          </Grid>
                          {/* <Grid item className={clsx(classes.gradinetView,classes.linkBox)}> */}
                          <Grid item>
                            <CustomInput
                              variant="outlined"
                              fullWidth
                              disabled
                              value={user?.nodes[0].referral_code}
                              InputProps={{
                                endAdornment: (
                                  <React.Fragment>
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            user?.nodes[0].referral_code
                                          );
                                        }}
                                      >
                                        <FileCopyIcon fontSize="small" />
                                        <Typography>&nbsp;</Typography>
                                        <Typography variant="caption">
                                          Copy Link
                                        </Typography>
                                      </IconButton>
                                    </InputAdornment>
                                  </React.Fragment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
      </React.Fragment>
    )


  }



  const mapStateToProps = ({ adminReducer }) => {
    return {
      loadings: adminReducer.loadings,
      admin: adminReducer.admin,
      sponsorList: adminReducer.sponsorList,
      user: adminReducer.user,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      ResetErrors: () => dispatch(actionCreator.ResetErrors()),
      getAdmin: () => dispatch(actionCreator.getAdmin()),
      getUser: (data) => dispatch(actionCreator.getUser(data)),
      getSponsorList: (data) => dispatch(actionCreator.getSponsorList(data)),
      notify: (message, varient) =>
        dispatch(actionCreator.notify(message, varient)),
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(ScanQrCode);