import React, { useState, useEffect, forwardRef } from "react";
import { API } from "../../asset/api";
import { getWithExpiry, updateObject } from "../../asset/utility";
import {
  makeStyles,
  withStyles,
  useTheme,
  styled,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MaterialTable from "material-table";
import { useParams } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import Active from "@material-ui/icons/ToggleOff";
import Block from "@material-ui/icons/ToggleOn";
import { connect } from "react-redux";
import Styles from "../../components/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import * as actionCreator from "../../store/action/index";
import Spinner from "../../components/layout/Spinner/Spinner";
import {
  Link,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  AccountCircle,
  Clear,
  DeleteOutline,
  Refresh,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import { green } from "@material-ui/core/colors";

const CusTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "10px",
  },
}));

const DialogTitle = withStyles(styled)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      style={{
        display: "flex",
        justifyContent: "space-around",
      }}
      {...other}
    >
      <div style={{ width: "90%", marginTop: "0.6rem" }}>
        <Typography variant="h6">{children}</Typography>
      </div>
      <div style={{ width: "10%" }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: "18px", right: "20px" }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <AccountCircle {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const StaffList = (props) => {
  const classes = Styles();

  const { admin, notify, getBusinesses } = props;

  const [open, setOpen] = React.useState(false);
  const [staff_details, setStaff_details] = useState({
    email: "",
    role: "",
  });
  const [profileId, setProfileId] = useState(useParams().profileId || null);
  const [errorObj, setErrorObj] = useState({});
  const [id, setId] = useState(useParams().id || null);
  const [toggle, setToggle] = useState(false);
  const [view_staff, setView_Staff] = React.useState([]);
  const handleClickOpen = (e) => {
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  const handleChange1 = (event) => {
    setStaff_details({
      ...staff_details,
      [event.target.name]: event.target.value,
    });
  };

  const inviteStaff = () => {
    let storedToken = getWithExpiry("token");
    API.post(
      "/AddStaff",
      {
        business_id: id,
        email: staff_details.email,
        designation: staff_details.role,
        profileId: profileId,
      },
      { headers: { token: storedToken }, withCredentials: false }
    )
      .then((res) => {
        const { status, error, successMessage, errorMessage } = res.data;
        if (status) {
          setStaff_details({
            email: "",
            role: "",
          });
          notify(successMessage, "success");
          getStaffList();
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
      });
    handleClose();
  };
  const toggleStaffStatus = (value, staffId) => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      staffId: staffId,
      status: value,
    };
    API.post("/SetStaffStatus", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, message, error } = res.data;
        notify(message, "success");
        getStaffList();
      })
      .catch((err) => {
        notify(err?.response?.data?.message || err.message, "error");
      });
  };
  const removeStaff = (staffId) => {
    let storedToken = getWithExpiry("token");
    API.post(
      "/RemoveStaff",
      {
        business_id: id,
        profileId: profileId,
        staff_id: staffId,
      },
      { headers: { token: storedToken }, withCredentials: false }
    )
      .then((res) => {
        const { status, error, successMessage, errorMessage } = res.data;
        if (status) {
          getStaffList();
          notify(successMessage, "success");
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => notify(err?.response?.data?.errorMessage, "error"));
  };
  const columns = [
    {
      title: "Name",
      field: "staff_details.first_name",
      render: (view_staff) =>
        view_staff?.staff_details?.first_name
          ? view_staff?.staff_details?.first_name +
            " " +
            view_staff?.staff_details?.last_name
          : "-",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Email",
      field: "email",
      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Role",
      field: "designation",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
    {
      title: "Address",
      field: "staff_details.address_details.address",
      render: (view_staff) =>
        view_staff?.staff_details?.address_details?.address
          ? view_staff?.staff_details?.address_details?.address +
            ", " +
            view_staff?.staff_details?.address_details?.city +
            ", " +
            view_staff?.staff_details?.address_details?.state +
            ", " +
            view_staff?.staff_details?.address_details?.country +
            ", " +
            view_staff?.staff_details?.address_details?.pincode
          : "-",

      // 		view_staff?.staff_details?.address_details
      // 		? view_staff?.staff_details?.address_details?.address
      // 		? view_staff?.staff_details?.address_details?.address + ", "
      // 					: "" + view_staff?.staff_details?.address_details?.city
      // ? view_staff?.staff_details?.address_details?.city + ", "
      // : "" + view_staff?.staff_details?.address_details?.state
      // 	? view_staff?.staff_details?.address_details?.state + ", "
      // 	: "" + view_staff?.staff_details?.address_details?.country
      // 		? view_staff?.staff_details?.address_details?.country + ", "
      // 		: "" + view_staff?.staff_details?.address_details?.pincode
      // 				: "-",

      align: "center",
      sorting: false,
      searchable: true,
    },
    {
      title: "Status",
      field: "status",
      align: "center",
      sorting: false,
      searchable: true,
      editable: "never",
    },
  ];

  const getStaffList = () => {
    let storedToken = getWithExpiry("token");
    const reqBody = {
      profileId: profileId,
      business_id: id,
    };
    API.post("/GetStaffList", JSON.stringify(reqBody), {
      headers: {
        token: storedToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        const { status, staffs, errorMessage, successMessage, error } =
          res.data;
        if (status) {
          if (staffs.length > 0) {
            // console.log(staffs, "staffList-----");
            setView_Staff(staffs);
          } else {
            setView_Staff(null);
          }
        }
      })
      .catch((err) => {
        console.log("axios error: " + err);
      });
  };

  const handleClickOpens = (view_staff) => {
    setOpen(true);
    setView_Staff(view_staff);
  };

  useEffect(() => {
    if (staff_details.email == "" && staff_details.role == "") {
      getStaffList();

      return () => {};
    }
  }, []);

  return (
    <React.Fragment>
      <Grid item container id="buss_staff">
        {/* <Paper className={classes.paper} elevation={5}> */}
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            className={classes.resultContainer}
          >
            <Grid item>
              <Typography variant="h5" align="left">
                Business Staff
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
              <Divider />
            </Grid>

            <Grid container direction="column" className={classes.main}>
              <Grid style={{ right: 0, paddingBottom: "0.7rem" }}>
                <Button
                  variant="outlined"
                  className={classes.staff}
                  onClick={handleClickOpen}
                >
                  Add Staff
                </Button>
              </Grid>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  icons={tableIcons}
                  title={view_staff !== null ? "Members" : "No Members"}
                  columns={columns}
                  options={{
                    filtering: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 15],
                    emptyRowsWhenPaging: false,
                    maxBodyHeight: 1450,
                    padding: "default",
                    search: false,
                  }}
                  data={view_staff}
                  actions={[
                    (view_saff) => ({
                      icon: () => <DeleteOutline />,
                      tooltip: "Delete Business",
                      onClick: (event, view_staff) =>
                        removeStaff(view_staff.staff_id),
                    }),

                    (view_saff) => ({
                      disabled:
                        view_saff.status == "LINK_EXPIRED" ||
                        view_saff.status == "EMAIL_VERIFIED" ||
                        view_saff.status == "MAIL_SENT",

                      icon: () =>
                        view_saff.is_blocked === false ||
                        (view_saff.is_blocked == null &&
                          (view_saff.status === "REGISTRATION_COMPLETE" ||
                            view_saff.status === "DETAILS_UPDATED")) ? (
                          <Block style={{ color: "green" }} />
                        ) : (
                          <Active
                          // style={{ color: 'red' }}
                          />
                        ),
                      tooltip:
                        view_saff.is_blocked === false ||
                        (view_saff.is_blocked == null &&
                          (view_saff.status === "REGISTRATION_COMPLETE" ||
                            view_saff.status === "DETAILS_UPDATED"))
                          ? "Block"
                          : "Unblock",
                      onClick: (event, view_staff) =>
                        toggleStaffStatus(
                          !view_staff.is_blocked,
                          view_staff.staff_id
                        ),
                    }),
                  ]}
                />
              </div>
            </Grid>

            <div style={{ width: "100%" }}>
              <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                  // style={{ position: "relative", width: "40vw" }}
                >
                  Invite with Email
                </DialogTitle>

                <DialogContent style={{ width: "22vw" }}>
                  <FormControl>
                    <Grid container>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.first_row}
                      >
                        <Box>
                          <h4 style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Invite with Email
                          </h4>
                        </Box>
                        <Box
                          className={classes.btn_box}
                          style={{ marginLeft: "3rem" }}
                        >
                          <Button
                            variant="contained"
                            className={classes.invite}
                            onClick={inviteStaff}
                            disabled={
                              staff_details.email == "" ||
                              staff_details.role == ""
                            }
                          >
                            Invite
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        xs={12}
                        // style={{ marginLeft: "2rem" }}
                      >
                        <Box>
                          <CusTextField
                            style={{
                              marginBottom: "0.5rem",
                              marginTop: "1rem",
                              // margin: "1rem auto 0.5rem 2rem",
                            }}
                            error={errorObj.hasOwnProperty("email")}
                            id="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange1}
                            name="email"
                            value={staff_details.email}
                            helperText={errorObj?.email}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <CusTextField
                          error={errorObj.hasOwnProperty("role")}
                          id="manager"
                          label="Role"
                          variant="outlined"
                          fullWidth
                          style={{ marginTop: "1rem" }}
                          onChange={handleChange1}
                          name="role"
                          value={staff_details.role}
                          helperText={errorObj?.role}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </DialogContent>
              </Dialog>
            </div>
          </Grid>
        </Grid>
        {/* </Paper > */}
      </Grid>
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    sponsorList: adminReducer.sponsorList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getSponsorList: (data) => dispatch(actionCreator.getSponsorList(data)),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    updatePassword: (data) => dispatch(actionCreator.updatePassword(data)),
    updateCustomFee: (data) => dispatch(actionCreator.updateCustomFee(data)),
    updateOverdraftValue: (data) =>
      dispatch(actionCreator.updateOverdraftValue(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    getBusinesses: () => dispatch(actionCreator.getBusinesses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
