import {
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Grid,
 InputLabel,
 makeStyles,
 MenuItem,
 Select,
 TextField,
 useTheme,
 withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actionCreator from "../../../../store/action/index";
import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import { updateObjectProperty } from "../../../../asset/utility";

const CustomButton = withStyles({
 root: {
  borderRadius: 8,
  justifySelf: "right",
  border: 0,
  color: "white",
  fontStyle: "normal",
  background: "#359DB6",
  "&:hover": {
   background: "#62c3db",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#8ab0b9",
 },
})((props) => <Button {...props} />);

const SuccessButton = withStyles({
 root: {
  borderRadius: 8,
  border: 0,
  color: "white",
  fontFamily: "Manrope",
  fontStyle: "normal",
  background: "#10ca73",
  "&:hover": {
   background: "#87f891",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#758876",
 },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
 confirmButton: {
  textAlign: "right",
 },
}));
const TaskModal = (props) => {
 const theme = useTheme();
 const classes = useStyles(theme);
 const [task, setTask] = useState();

 const onTaskValueChanged = (e) => {
  e.persist();
  let name = e.target.name;
  let value = e.target.value;
  if (name === "reward" && value !== "") {
   value = Number(value);
  }
  setTask(updateObjectProperty(task, name, value));
 };

 const createTask = () => {
  props.createTask(task);
  props.onClose();
 };

 const editTask = () => {
  props.editTask(task);
  props.onClose();
 };

 const onDelete = () => {
  props.deleteTask();
  props.onClose();
 };
 useEffect(() => {
  setTask({
   taskName: props.rowData ? props.rowData.taskName : "",
   category: props.rowData ? props.rowData.category : "",
   reward: props.rowData ? props.rowData.reward : null,
   frequency: props.rowData ? props.rowData.frequency : "",
   taskStatus: props.rowData ? props.rowData.isActive : true,
  });
 }, [props.rowData]);

 return (
  <>
   <Dialog
    aria-labelledby="customized-dialog-title"
    open={props?.open}
    onClose={props.onClose}
   >
    {props.title !== "Delete Task" ? (
     <Grid>
      <DialogTitle>{props.title}</DialogTitle>
     </Grid>
    ) : null}
    {props.open == true ? (
     <>
      {" "}
      <DialogContent>
       {props.title == "Delete Task" ? (
        <>
         <DialogTitle>{`Do you want to delete "${props.rowData.taskName}" task ?`}</DialogTitle>
         <Grid style={{ textAlign: "center", padding: "8px 15px" }}>
          <CustomButton
           //   className={classes.confirmButton}
           onClick={onDelete}
          >
           Confirm
          </CustomButton>
         </Grid>
        </>
       ) : (
        <>
         <TextField
          type="text"
          value={task.taskName}
          name="taskName"
          label="Task Name"
          onChange={onTaskValueChanged}
          fullWidth
         />
         <TextField
          type="number"
          value={task.reward}
          name="reward"
          label="Reward"
          onChange={onTaskValueChanged}
          fullWidth
         />
         <InputLabel style={{ margin: "6% 0 0 0" }}>Category</InputLabel>
         <Select
          name="category"
          value={task.category}
          required
          onChange={onTaskValueChanged}
          fullWidth
         >
          <MenuItem value={1}>Age Group 1</MenuItem>
          <MenuItem value={2}>Age Group 2</MenuItem>
          <MenuItem value={3}>Age Group 3</MenuItem>
         </Select>
         <InputLabel style={{ margin: "6% 0 0 0" }}>Frequency</InputLabel>
         <Select
          name="frequency"
          value={task.frequency}
          required
          onChange={onTaskValueChanged}
          fullWidth
         >
          <MenuItem value={"once"}>Once</MenuItem>
          <MenuItem value={"daily"}>Daily</MenuItem>
          <MenuItem value={"weekly"}>Weekly</MenuItem>
          <MenuItem value={"monthly"}>Monthly</MenuItem>
          <MenuItem value={"fortnightly"}>Fortnight</MenuItem>
          <MenuItem value={"annual"}>Annually</MenuItem>
         </Select>

         {props.title == "Edit Task" ? (
          <>
           <InputLabel style={{ margin: "6% 0 0 0" }}>Task Status</InputLabel>
           <Select
            name="taskStatus"
            value={task.taskStatus}
            required
            onChange={onTaskValueChanged}
            fullWidth
           >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
           </Select>
          </>
         ) : null}

         <Grid style={{ textAlign: "center", margin: "3% 0 0 0" }}>
          <SuccessButton
           variant="contained"
           type="submit"
           onClick={props.title == "Add Task" ? createTask : editTask}
           disabled={
            !task.taskName?.length ||
            task.reward === undefined ||
            task.reward <= 0 ||
            !task.category ||
            !task.frequency
           }
          >
           {"Submit "}
          </SuccessButton>
         </Grid>
        </>
       )}
      </DialogContent>
     </>
    ) : null}
   </Dialog>
  </>
 );
};

export default TaskModal;
