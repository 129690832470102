import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "100vh",
  overflowY: "auto",
  // height: "80vh",
  backgroundColor: "#303030",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  padding: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "90vh",
  backgroundColor: "#303030",
  boxShadow: 24,
  display: "flex",
  // justifyContent: "center",
  padding: 4,
};

function TransitionsModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { disableHeight, disableMargin, disableCenterBody } = props;

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper
            style={
              props.title !== "Exchange Directory Details" ? style : style2
            }
          >
            <Grid item xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: disableHeight ? "8%" : "15%",
                  marginTop: disableMargin ? "none" : "1rem",
                }}
              >
                <Typography variant="h6" style={{ color: "#359DB6" }}>
                  {props.title}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  textAlign: disableCenterBody ? "none" : "center",
                  height: disableHeight ? "auto" : "85%",
                }}
              >
                {props.element}
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}

export default TransitionsModal;
