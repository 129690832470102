import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Link,
  Grid,
  Paper,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@material-ui/core";
import * as actionCreator from "../../../../store/action/index";
import { updateObjectProperty } from "../../../../asset/utility";
import Styles from "../../../../components/styles";

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

const Password = (props) => {
  const classes = Styles();
  const theme = useTheme();

  const {
    updatePassword,
    ResetErrors,
    user,
    loadings,
  } = props;

  const [inputPassword, setInputPassword] = useState({
    currentPassword: "",
    newPassword: "",
    rePassword: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    passwordError: "",
    newPasswordError: "",
    rePasswordError: "",

  });
  const [validData, setValidData] = useState({
    validPassword: false,
    validNewPassword: false,
    validRePassword: false,

  });

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };

    if (field === "currentPassword") {
      if (value.trim().length === 0) {
        errorMessagesCopy.passwordError = "Password is required";
        validDataCopy.validPassword = false;
      } else {
        errorMessagesCopy.passwordError = "";
        validDataCopy.validPassword = true;
      }
    } else if (field === "newPassword") {
      let passwordRegEX = /(?=.*\d).{8,}/;
      if (value.trim().length === 0) {
        errorMessagesCopy.newPasswordError = "Password is required";
        validDataCopy.validNewPassword = false;
      } else if (!passwordRegEX.test(value.trim())) {
        errorMessagesCopy.newPasswordError =
          "Password must contain at least 8 characters, including a number";
        validDataCopy.validNewPassword = false;
      } else {
        errorMessagesCopy.newPasswordError = "";
        validDataCopy.validNewPassword = true;
      }
    } else if (field === "rePassword") {
      if (value !== inputPassword.newPassword) {
        errorMessagesCopy.rePasswordError = "Passwords don't match";
        validDataCopy.validRePassword = false;
      } else {
        errorMessagesCopy.rePasswordError = "";
        validDataCopy.validRePassword = true;
      }
    }
    setErrorMessages(errorMessagesCopy);
    setValidData(validDataCopy);
  }

  const onPasswordValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInputPassword(updateObjectProperty(inputPassword, name, value));
    validateField(name, value);
  };

  const onPasswordSave = () => {
    if (user && validData.validNewPassword && validData.validRePassword) {
      updatePassword({
        id: user?._id,
        password: inputPassword.newPassword,
      });
      setInputPassword({
        currentPassword: "",
        newPassword: "",
        rePassword: "",
      });
    }
  };

  return (
    <React.Fragment>
      <Grid container >
        <Paper className={classes.paper} id="password" elevation={5}>
          <Grid container spacing={1}>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" align="left">
                  Change Password
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Divider />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <CustomInput
                  error={errorMessages?.newPasswordError?.length > 0}
                  helperText={errorMessages?.newPasswordError}
                  variant="outlined"
                  name="newPassword"
                  type="password"
                  label="New Password"
                  placeholder="New Password"
                  required
                  value={inputPassword.newPassword}
                  onChange={onPasswordValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomInput
                  error={errorMessages?.rePasswordError?.length > 0}
                  helperText={errorMessages?.rePasswordError}
                  variant="outlined"
                  name="rePassword"
                  type="password"
                  label="Re-New Password"
                  placeholder="Re-New Password"
                  required
                  value={inputPassword.rePassword}
                  onChange={onPasswordValueChanged}
                />
              </Grid>
              <Grid item>
                <SuccessButton
                  onClick={onPasswordSave}
                  disabled={
                    !(
                      validData.validNewPassword &&
                      validData.validRePassword
                    ) || loadings.updatePassword
                  }
                  fullWidth
                >
                  Save
                </SuccessButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    user: adminReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    updatePassword: (data) => dispatch(actionCreator.updatePassword(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);