import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Link, Grid, Paper, Typography, Divider } from "@material-ui/core";
import clsx from "clsx";
import {
  AccountBox,
  VpnKey,
  SwapVertOutlined,
  AccountCircle,
} from "@material-ui/icons";
import { forwardRef, useRef } from "react";
import phone from "phone";
import * as actionCreator from "../../../store/action";
import { updateObjectProperty } from "../../../asset/utility";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  Remove,
  Search,
  SaveAlt,
  ViewColumn,
} from "@material-ui/icons";
import EuroIcon from "@material-ui/icons/Euro";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CropFreeIcon from "@material-ui/icons/CropFree";
import FilterList from "@material-ui/icons/FilterList";
import Transaction from "./components/transaction";
import Nodes from "./components/nodes";
import Password from "./components/password";
import SponsorList from "./components/sponsorList";
import ScanQrCode from "./components/scanQrCode";
import Banking from "./components/banking";
import Kyc from "./components/kyc";
import UserInfo from "./components/userInfo";
import UserActivity from "./components/userActivity";
import Company from "./components/company";
import CBC from "./components/cbc";
import Styles from "../../../components/styles";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Stocks from "./components/Stocks";
import BankAccounts from "./components/bankAccounts";
import BankAccountLogo from "../../../asset/images/bankAccounts";

var screenWidth = window.screen.width;
const isScreenSizeEnought = screenWidth > 996;
const navTextStyle = !isScreenSizeEnought
  ? { position: "absolute", top: 58 }
  : {};
const hoverText = { fontSize: 14 };

const User = (props) => {
  const classes = Styles();
  const {
    admin,
    getUser,
    user,
    getCompany,
    getAssectsType,
  } = props;

  const [id, setId] = useState(useParams().id || null);
  const [activeSection, setActiveSection] = useState("USER");

  const getCompany1 = (id) => {
    if (activeSection !== "COMPANY") {
      return;
    } else {
      getCompany(id);
    }
  };

  // const getKycData1 = () => {
  // 	if (activeSection !== "USER") {
  // 		return;
  // 	} else {
  // 		if (user?.kyc?.status == false) {
  // 			notify("User din't initiate his kyc", "info");
  // 		} else {
  // 			if (user?.kyc?.status == true && user?.kyc?.id != null) {
  // 				let kycId = user?.kyc?.id;
  // 				getKycData(kycId);
  // 			} else if (user?.kyc?.status == true && user?.kyc?.id == null) {
  // 				notify("User kyc incomplete", "info");
  // 			} else {
  // 				console.log("...");
  // 			}
  // 		}
  // 	}
  // };

  // useEffect(() => {
  // 	let kycId = user?.kyc?.id;
  // 	if (kycData) {
  // 		getKycDocsData(kycId);
  // 	}
  // }, [kycData]);

  // useEffect(() => {
  // 	getKycData1();
  // }, [activeSection == "USER"]);

  useEffect(() => {
    getCompany1(id);
  }, [activeSection == "COMPANY"]);

  useEffect(() => {
    getAssectsType();
  }, [activeSection == "CBC"]);

  useEffect(() => {
    if (!user) getUser(id);
  }, []);

  return (
    <React.Fragment>
      {admin?.level <= 4 ? (
        <Grid
          container
          className={clsx(classes.root, classes.rowView)}
          spacing={3}
        >
          <Grid
            item
            direction="column"
            style={{ padding: isScreenSizeEnought ? null : 20 }}
            xs={isScreenSizeEnought ? 3 : 2}
            className={classes.sidePlane}
          >
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "USER"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("USER")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                <AccountBox elevation={5} className={clsx(classes.menuIcon)} />
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  User
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {/* <Grid
							container
							item
							className={clsx(classes.columnView, classes.linkBlock)}
							xs={3}
							spacing={1}
						>
							<Grid item>
								<Link
									onClick={() => setActiveSection("USER ACTIVITY")}
									style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
								>
									<Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									>
										<AccountBox className={classes.menuIcon} />
									</Paper>
								</Link>
							</Grid>
							<Grid item>
								<Typography varient="subtitle1" align="center" noWrap>
									User Activity
								</Typography>
							</Grid>
						</Grid>

						<Grid
							container
							item
							className={clsx(classes.columnView, classes.linkBlock)}
							xs={3}
							spacing={1}
						>
							<Grid item>
								<Link
									onClick={() => setActiveSection("KYC")}
									style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
								>
									<Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									>
										<AccountBox className={classes.menuIcon} />
									</Paper>
								</Link>
							</Grid>
							<Grid item>
								<Typography varient="subtitle1" align="center" noWrap>
									Kyc
								</Typography>
							</Grid>
						</Grid> */}
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "STOCKS"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("STOCKS")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <ShowChartIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Stocks
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "BANKING"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("BANKING")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <AccountBalanceIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Banking
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "BANKACCOUNT"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("BANKACCOUNT")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item style={{ margin: "10px" }}>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <BankAccountLogo className={classes.menuIcon} />
                {/* <AccountBalanceIcon className={classes.menuIcon} /> */}
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Bank Account
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "CBC"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("CBC")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <EuroIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  CBC
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {/*<Grid
							item
							className={clsx(classes.rowMenuView,classes.hoverChild, classes.linkBlock)}>
							<Grid item>
								<Link
									onClick={() => setActiveSection("NODES")}
									style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
								>
									{/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
            {/* <AccountTreeIcon className={classes.menuIcon} /> */}
            {/* </Paper> */}
            {/* </Link>
							</Grid>
							<Grid item
              style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
                >
								<Typography className={classes.menuText} style={!isScreenSizeEnought ? hoverText : {}}>
									Nodes
								</Typography>
							</Grid>
						</Grid>
						<Divider />*/}
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "SPONSORLIST"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("SPONSORLIST")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <GroupAddIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Sponsor List
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "SCANQRCODE"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("SCANQRCODE")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <CropFreeIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Scan Qr Code
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "TRANSACTION"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("TRANSACTION")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <SwapVertOutlined className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Transaction
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "COMPANY"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("COMPANY")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <BusinessIcon className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Company
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              className={clsx(
                classes.linkBlock,
                classes.rowMenuView,
                classes.hoverChild,
                activeSection == "PASSWORD"
                  ? classes.iconSelected
                  : classes.iconButton
              )}
              onClick={() => setActiveSection("PASSWORD")}
              style={{
                cursor: "pointer",
                justifyContent: isScreenSizeEnought ? null : "center",
                position: "relative",
              }}
            >
              <Grid item>
                {/* <Paper
										className={clsx(classes.paper, classes.menuPaper)}
										elevation={5}
									> */}
                <VpnKey className={classes.menuIcon} />
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                style={navTextStyle}
                className={clsx(
                  !isScreenSizeEnought ? classes.hoverDisplay : ""
                )}
              >
                <Typography
                  className={classes.menuText}
                  style={!isScreenSizeEnought ? hoverText : {}}
                >
                  Password
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Grid>

          <Grid item xs={isScreenSizeEnought ? 9 : 10}>
            {/* User-details --- pk  */}
            {user?.nodes && activeSection === "USER" ? (
              <>
                <UserInfo />
              </>
            ) : null}

            {user && activeSection === "COMPANY" ? (
              <>
                <Company />
              </>
            ) : null}
            {/* KYC--- pk */}
            {/* {user?.nodes && activeSection === "KYC" ? (
							<>
								<Kyc />
							</>
						) : null} */}

            {user?.nodes && activeSection === "NODES" ? (
              <>
                <Nodes />
              </>
            ) : null}
            {user?.nodes && activeSection === "SPONSORLIST" ? (
              <>
                <SponsorList />
              </>
            ) : null}
            {user?.nodes && activeSection === "SCANQRCODE" ? (
              <>
                <ScanQrCode />
              </>
            ) : null}
            {activeSection === "TRANSACTION" ? (
              <>
                <Transaction />
              </>
            ) : null}
            {user && activeSection === "BANKING" ? (
              <>
                <Banking />
              </>
            ) : null}
            {user && activeSection === "BANKACCOUNT" ? (
              <>
                <BankAccounts />
              </>
            ) : null}
            {user && activeSection === "CBC" ? (
              <>
                <CBC />
              </>
            ) : null}
            {user && activeSection === "STOCKS" ? <Stocks /> : null}
            {user && activeSection === "PASSWORD" ? <Password /> : null}
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    admin: adminReducer.admin,
    sponsorList: adminReducer.sponsorList,
    companies: adminReducer.companies,
    user: adminReducer.user,
    kycData: adminReducer.kycData,
    assestsTypeData: adminReducer.assestsTypeData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getAdmin: () => dispatch(actionCreator.getAdmin()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    getCompany: (data) => dispatch(actionCreator.getCompany(data)),
    getAssectsType: () => dispatch(actionCreator.getAssectsType()),

    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
