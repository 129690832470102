import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Button, Tabs, Tab } from "@material-ui/core";
import CustomTabs from "../../pages/dashboard/user/components/Tabs/index";
import AdminApprovalPage from "../../components/layout/adminApprovalPage";
import CbcApproval from "./cbcApproval";
import { componentAccess } from "../../components/common/method";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
 root: {
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
 customTableButton: {
  display: "flex",
  justifyContent: "flex-end",
  padding: "2px 0px",
 },
 inputStyle: {
  //background: "#eeededd7",
  borderRadius: 8,
  border: 0,
  fontFamily: "Manrope",
 },
 container: {
  width: "100%",
  marginTop: theme.spacing(1),
  padding: "1% 0% 2% 3.6%",
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingLeft: theme.spacing(8) + 1,
  },
  borderRadius: "15px",
  "&.MuiToolbar-root": {
   color: theme.palette.background.default,
  },
  "& .MuiTable-root": {
   "& .MuiTableRow-root": {
    "&:nth-child(even)": {
     background: theme.palette.background.paper,
    },
    "&:nth-child(odd)": {
     background: theme.palette.background.default,
    },
   },
  },
 },
}));

const AdminApproval = (props) => {
 const classes = useStyles();
 const { admin } = props;
 const [activeTab, setActiveTab] = React.useState(0);

 const headings = ["AdminApproval", "CBC Approval"];
 const contents = [<AdminApprovalPage />, <CbcApproval />];

 const permit = componentAccess(
  admin?.level,
  admin?.type,
  "CBCApproval",
  "approveCBCTransfer"
 );

 //  const handleActiveTab = (event, value) => {
 //   setActiveTab(value);
 //  };

 //  const options = ["Assets", "Instruments", "Exchange Directory"];
 //  function a11yProps(index) {
 //   return {
 //    id: `simple-tab-${index}`,
 //    "aria-controls": `simple-tabpanel-${index}`,
 //   };
 //  }

 return (
  <>
   {permit ? (
    <Grid item xs={12} className={classes.container}>
     <CustomTabs headings={headings} contents={contents} />
    </Grid>
   ) : (
    <Grid
     container
     className={classes.root}
     spacing={1}
     style={{ maxWidth: "100%" }}
    >
     <Grid item xs={12}>
      <AdminApprovalPage />
     </Grid>
    </Grid>
   )}
  </>
 );
};

const mapStateToProps = ({ adminReducer }) => {
 return {
  admin: adminReducer.admin,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminApproval);
