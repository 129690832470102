import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { isEmpty } from "lodash";
import Styles from "../../../../components/styles";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import * as actionCreator from "../../../../store/action/index";
import { updateObjectProperty } from "../../../../asset/utility";
import GoogleMaps from "../../../../components/auth/addressInput";
import MuiPhoneNumber from "material-ui-phone-number";
import { Autocomplete } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import { UAParser } from "ua-parser-js";
import phone from "phone";
import UserActivity from "./userActivity";
import Kyc from "./kyc";
import { componentAccess } from "../../../../components/common/method";
const CustomButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#359DB6",
    "&:hover": {
      background: "#62c3db",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#8ab0b9",
  },
})((props) => <Button {...props} />);

const CustomInput = withStyles({
  root: {
    //background: "#eeededd7",
    borderRadius: 8,
    border: 0,
    width: "100%",
    fontFamily: "Manrope",
  },
})((props) => <TextField {...props} />);

const SuccessButton = withStyles({
  root: {
    borderRadius: 8,
    border: 0,
    color: "white",
    fontFamily: "Manrope",
    fontStyle: "normal",
    background: "#10ca73",
    "&:hover": {
      background: "#87f891",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  disabled: {
    background: "#758876",
  },
})((props) => <Button {...props} />);

export const UserDetails = (props) => {
  const result = new UAParser().getResult();

  const classes = Styles();
  const theme = useTheme();
  const {
    loadings,
    getUser,
    updateUser,
    updateUserDetails,
    user,
    notify,
    admin,
  } = props;

  const [id, setId] = useState(useParams().id || null);
  const [userMod, setUserMod] = useState(false);
  const [inputUser, setInputUser] = useState(null);
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(false);
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [countriesIso2, setCountriesIso2] = useState();
  const [selectedState, setSelectedState] = useState();
  const [statesIso2, setStatesIso2] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [inputPassword, setInputPassword] = useState({
    currentPassword: "",
    newPassword: "",
    rePassword: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstNameError: "",
    lastNameError: "",
    userNameError: "",
    emailError: "",
    phoneError: "",
    dobError: "",
    addressError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    pincodeError: "",
    passwordError: "",
    newPasswordError: "",
    rePasswordError: "",
    feeError1: "",
    feeError2: "",
    feeError3: "",
    feeError4: "",
    feeError5: "",
    feeError6: "",
    overdraftError: "",
  });
  const [validData, setValidData] = useState({
    validFirstName: false,
    validLastName: false,
    validEmail: false,
    validuserName: false,
    validPhone: false,
    validDob: false,
    validAddress: false,
    validCountry: false,
    validState: false,
    validCity: false,
    validPincode: false,
    validPassword: false,
    validNewPassword: false,
    validRePassword: false,
    validFee1: false,
    validFee2: false,
    validFee2: false,
    validFee3: false,
    validFee4: false,
    validFee5: false,
    validFee6: false,
    validOverdraft: false,
  });

  const handleEmailClick = () => {
    setShow(false);
    setShow1(true);
  };

  const onUserSave = () => {
    if (
      errorMessages?.firstNameError?.length === 0 &&
      errorMessages?.lastNameError?.length === 0 &&
      errorMessages?.phoneError?.length === 0 &&
      errorMessages?.dobError?.length === 0 &&
      errorMessages?.addressError?.length === 0 &&
      errorMessages?.countryError?.length === 0 &&
      errorMessages?.stateError?.length === 0 &&
      errorMessages?.cityError?.length === 0 &&
      errorMessages?.pincodeError?.length === 0 &&
      user &&
      inputUser
    ) {
      if (
        inputUser?.hasOwnProperty("email") &&
        Object?.keys(inputUser).length == 1
      ) {
        updateUser({
          id: user?._id,
          changes: inputUser,
        });
      } else if (!inputUser?.hasOwnProperty("email")) {
        updateUserDetails({
          id: user?._id,
          changes: inputUser,
        });
      } else {
        // updateUser({
        //   id: user?._id,
        //   changes: inputUser,
        // });
        // updateUserDetails({
        //   id: user?._id,
        //   changes: inputUser,
        // });
        console.log("...");
      }
      setShow(true);
      setShow1(false);
      onUserBack();
    }
  };

  const onUserBack = () => {
    setUserMod(!userMod);
    setInputUser(null);
    setShow(true);
    setShow1(false);
    setErrorMessages({
      firstNameError: "",
      lastNameError: "",
      userNameError: "",
      emailError: "",
      phoneError: "",
      dobError: "",
      addressError: "",
      countryError: "",
      stateError: "",
      cityError: "",
      pincodeError: "",
      passwordError: "",
      newPasswordError: "",
      rePasswordError: "",
      feeError1: "",
      feeError2: "",
      feeError3: "",
      feeError4: "",
      feeError5: "",
      feeError6: "",
      overdraftError: "",
    });
    setValidData({
      validFirstName: false,
      validLastName: false,
      validEmail: false,
      validuserName: false,
      validPhone: false,
      validDob: false,
      validAddress: false,
      validCountry: false,
      validState: false,
      validCity: false,
      validPincode: false,
      validPassword: false,
      validNewPassword: false,
      validRePassword: false,
      validFee1: false,
      validFee2: false,
      validFee3: false,
      validFee4: false,
      validFee5: false,
      validFee6: false,
      validOverdraft: false,
    });
  };

  const onUserAddressValueChanged = (name, value) => {
    if (name === "address_details.country") {
      setSelectedCountry(value);
    } else if (name === "address_details.state") {
      setSelectedState(value);
    }
    if (value) {
      setInputUser(updateObjectProperty(inputUser, name, value));
    } else {
      setInputUser(updateObjectProperty(inputUser, name, ""));
    }

    validateField(name, value);
  };

  const onUserValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInputUser(updateObjectProperty(inputUser, name, value));
    validateField(name, value);
  };

  const onUserPhoneValueChanged = (value) => {
    let name = "phone";

    setInputUser(updateObjectProperty(inputUser, name, value));
    validateField(name, value);
  };

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };
    // let companyErrorsCopy = { ...companyErrors };
    // let validCompanyDataCopy = { ...validCompanyData };

    if (field === "firstName" || field === "lastName") {
      if (value.trim().length === 0) {
        if (field === "firstName") {
          errorMessagesCopy.firstNameError = "First name is required";
          validDataCopy.validFirstName = false;
        } else if (field === "lastName") {
          errorMessagesCopy.lastNameError = "Last name is required";
          validDataCopy.validLastName = false;
        }
      } else if (!/^[A-Za-z ]+$/.test(value.trim())) {
        if (field === "firstName") {
          errorMessagesCopy.firstNameError =
            "Name should contain only alphabets";
          validDataCopy.validFirstName = false;
        } else if (field === "lastName") {
          errorMessagesCopy.lastNameError =
            "Name should contain only alphabets";
          validDataCopy.validLastName = false;
        }
      } else {
        if (field === "firstName") {
          validDataCopy.validFirstName = true;
          errorMessagesCopy.firstNameError = "";
        } else if (field === "lastName") {
          validDataCopy.validLastName = true;
          errorMessagesCopy.lastNameError = "";
        }
      }
    } else if (field === "email") {
      let emailRegEX =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (value.trim().length === 0) {
        errorMessagesCopy.emailError = "This field is required";
        validDataCopy.validEmail = false;
      } else if (!emailRegEX.test(value.trim())) {
        errorMessagesCopy.emailError = "please enter a valid email address";
        validDataCopy.validEmail = false;
      } else {
        errorMessagesCopy.emailError = "";
        validDataCopy.validEmail = true;
      }
    } else if (field === "currentPassword") {
      if (value.trim().length === 0) {
        errorMessagesCopy.passwordError = "Password is required";
        validDataCopy.validPassword = false;
      } else {
        errorMessagesCopy.passwordError = "";
        validDataCopy.validPassword = true;
      }
    } else if (field === "newPassword") {
      let passwordRegEX = /(?=.*\d).{8,}/;
      if (value.trim().length === 0) {
        errorMessagesCopy.newPasswordError = "Password is required";
        validDataCopy.validNewPassword = false;
      } else if (!passwordRegEX.test(value.trim())) {
        errorMessagesCopy.newPasswordError =
          "Password must contain at least 8 characters, including a number";
        validDataCopy.validNewPassword = false;
      } else {
        errorMessagesCopy.newPasswordError = "";
        validDataCopy.validNewPassword = true;
      }
    } else if (field === "rePassword") {
      if (value !== inputPassword.newPassword) {
        errorMessagesCopy.rePasswordError = "Passwords don't match";
        validDataCopy.validRePassword = false;
      } else {
        errorMessagesCopy.rePasswordError = "";
        validDataCopy.validRePassword = true;
      }
    } else if (field === "userName") {
      let userIdRegEX = /^[a-z0-9]+$/i;
      if (value.trim().length === 0) {
        errorMessagesCopy.userIdError = "User Name is required";
        validDataCopy.validuserId = false;
      } else if (!userIdRegEX.test(value.trim())) {
        errorMessagesCopy.userIdError =
          "User Name must contain alphabets and numbers only";
        validDataCopy.validuserId = false;
      } else {
        errorMessagesCopy.userIdError = "";
        validDataCopy.validuserId = true;
      }
    } else if (field === "phone" || field === "companyPhone") {
      let result = phone(value, "", true);
      if (value === "") {
        if (field === "phone") {
          errorMessagesCopy.phoneError = "Phone number is required ";
          validDataCopy.validPhone = false;
        }
      } else if (result.length) {
        if (field === "phone") {
          errorMessagesCopy.phoneError = "";
          validDataCopy.validPhone = true;
        }
      } else {
        if (field === "phone") {
          errorMessagesCopy.phoneError = "Put the correct phone number";
          validDataCopy.validPhone = false;
        }
      }
    } else if (field === "dateOfBirth") {
      let now = new Date().toISOString().slice(0, 10);
      if (value === "") {
        errorMessagesCopy.dobError = "Date of birth is required ";
        validDataCopy.validDob = false;
      } else if (value >= now) {
        errorMessagesCopy.dobError = "Date of birth cannot be future !! ";
        validDataCopy.validDob = false;
      } else {
        errorMessagesCopy.dobError = "";
        validDataCopy.validDob = true;
      }
    } else if (field.includes("address_details")) {
      if (value === "" || value === null) {
        if (field === "address_details.address") {
          errorMessagesCopy.addressError = "Address is required";
          validDataCopy.validAddress = false;
        } else if (field === "address_details.country") {
          errorMessagesCopy.countryError = "Country is required";
          validDataCopy.validCountry = false;
        } else if (field === "address_details.pincode") {
          errorMessagesCopy.pincodeError = "Pincode is required";
          validDataCopy.validPincode = false;
        }
      } else {
        if (field === "address_details.address") {
          errorMessagesCopy.addressError = "";
          validDataCopy.validAddress = true;
        } else if (field === "address_details.country") {
          errorMessagesCopy.countryError = "";
          validDataCopy.validCountry = true;
        } else if (field === "address_details.state") {
          errorMessagesCopy.stateError = "";
          validDataCopy.validState = true;
        } else if (field === "address_details.city") {
          errorMessagesCopy.cityError = "";
          validDataCopy.validCity = true;
        } else if (field === "address_details.pincode") {
          errorMessagesCopy.pincodeError = "";
          validDataCopy.validPincode = true;
        }
      }
    }
    setErrorMessages(errorMessagesCopy);
    setValidData(validDataCopy);
    // setCompanyErrors(companyErrorsCopy);
    // setValidCompanyData(validCompanyDataCopy);
  };

  useEffect(() => {
    if (!user) getUser(id);
  }, []);

  useEffect(() => {
    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          let countries = response;
          countries.push({ name: "Cogito", iso2: "COG" });
          setCountries(countries);
          let countriesIso = {};
          response.map((country) => {
            countriesIso[country.name] = country.iso2;
          });
          setCountriesIso2(countriesIso);
        }
      })
      .catch((error) =>
        notify("Coudn't fetch countries please try again", "error")
      );
  }, []);

  useEffect(() => {
    if (inputUser) {
      for (const property in inputUser) {
        let patt = new RegExp(/[.]/g);
        let objectProp = patt.test(property);
        if (
          inputUser[property] == user[property] ||
          (objectProp &&
            inputUser[property] ==
              user[property.split(".")[0]][property.split(".")[1]])
        ) {
          delete inputUser[property];
        }
      }
      if (isEmpty(inputUser)) setInputUser(null);
    }
  }, [user, inputUser]);

  useEffect(() => {
    if (selectedCountry && selectedCountry !== "Cogito") {
      var selectedCountryCode = countriesIso2[selectedCountry];
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      fetch(
        `https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          let states = [];
          let statesIso = {};
          if (response) {
            response.map((state) => {
              states.push(state.name);
              statesIso[state.name] = state.iso2;
            });
            setStates(states);
            setStatesIso2(statesIso);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setStates(["Cogito"]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState && selectedCountry !== "Cogito") {
      var selectedCountryCode = countriesIso2[selectedCountry];
      var selectedStateCode = statesIso2[selectedState];
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", process.env.REACT_APP_ADDRESS_API_KEY);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      fetch(
        `https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states/${selectedStateCode}/cities`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          let cities = [];
          if (response) {
            response.map((city) => {
              cities.push(city.name);
            });
            setCities(cities);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setCities(["Cogito"]);
    }
  }, [selectedState]);

  return (
    <React.Fragment>
      <Grid item container id="user">
        <Grid item container xs={12} alignItems="center" spacing={2}>
          <Grid item container xs={12} className={classes.resultContainer} style={{padding:'2rem'}}>
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" align="left">
                  User
                </Typography>
              </Grid>
              <Grid item>
                {userMod ? (
                  <Grid container item spacing={1}>
                    <Grid item>
                      <CustomButton
                        onClick={onUserBack}
                        disabled={loadings.updateuser}
                      >
                        Back
                      </CustomButton>
                    </Grid>
                    <Grid item>
                      <SuccessButton
                        onClick={onUserSave}
                        disabled={
                          !(
                            errorMessages.emailError?.length === 0 &&
                            errorMessages.firstNameError?.length === 0 &&
                            errorMessages.lastNameError?.length === 0 &&
                            errorMessages.phoneError?.length === 0 &&
                            errorMessages.dobError?.length === 0 &&
                            errorMessages.addressError?.length === 0 &&
                            errorMessages.countryError?.length === 0 &&
                            errorMessages.stateError?.length === 0 &&
                            errorMessages.cityError?.length === 0 &&
                            errorMessages.pincodeError?.length === 0 &&
                            user &&
                            inputUser
                          )
                        }
                      >
                        Save
                      </SuccessButton>
                    </Grid>
                  </Grid>
                ) : componentAccess(
                    admin?.level,
                    admin?.type,
                    "Users",
                    "editUserDetails"
                  ) ? (
                  <CustomButton
                    onClick={() => setUserMod(!userMod)}
                    disabled={loadings.updateuser || !Boolean(user)}
                  >
                    Modify
                  </CustomButton>
                ) : null}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  User Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h6" color="textSecondary" align="left">
                  {user.userName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Email
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={8}>
                {!userMod ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {user.email}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      <CustomInput
                        variant="outlined"
                        type="email"
                        name="email"
                        label="Email"
                        error={errorMessages?.emailError?.length > 0}
                        helperText={errorMessages?.emailError}
                        //value = {inputUser.email}
                        defaultValue={user.email}
                        onChange={onUserValueChanged}
                        disabled={show}
                        style={{ width: "80%" }}
                      />
                      <IconButton
                        size="small"
                        onClick={handleEmailClick}
                        style={{
                          color: "#ff7300",
                          marginLeft: "20px",
                          marginTop: "12px",
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" align="left">
                        Updated email won't reflect until verified through link
                        sent to the new email
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Name
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={8}>
                {!userMod ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {user.firstName + " " + user.lastName}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        variant="outlined"
                        type="text"
                        name="firstName"
                        label="First Name"
                        error={errorMessages?.firstNameError?.length > 0}
                        helperText={errorMessages?.firstNameError}
                        //value = {inputUser.firstName}
                        defaultValue={user.firstName}
                        onChange={onUserValueChanged}
                        disabled={show1}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        variant="outlined"
                        type="text"
                        name="lastName"
                        label="Last Name"
                        error={errorMessages?.lastNameError?.length > 0}
                        helperText={errorMessages?.lastNameError}
                        //value = {inputUser.lastName}
                        defaultValue={user.lastName}
                        onChange={onUserValueChanged}
                        disabled={show1}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Phone
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                {!userMod ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                  >
                    {user.phone}
                  </Typography>
                ) : (
                  <MuiPhoneNumber
                    variant="outlined"
                    name="phone"
                    label="Phone Number"
                    //data-cy="user-phone"
                    //style={{background: '#eeededd7'}}
                    fullWidth
                    error={errorMessages?.phoneError?.length > 0}
                    helperText={errorMessages?.phoneError}
                    value={user.phone}
                    //defaultValue = {user.phone}
                    onChange={onUserPhoneValueChanged}
                    disabled={show1}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Date Of Birth
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                {!userMod ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                  >
                    {user.dateOfBirth}
                  </Typography>
                ) : (
                  <CustomInput
                    variant="outlined"
                    label="Date of Birth"
                    name="dateOfBirth"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errorMessages?.dobError?.length > 0}
                    helperText={errorMessages?.dobError}
                    //value = {inputUser.dateOfBirth}
                    defaultValue={user.dateOfBirth}
                    onChange={onUserValueChanged}
                    disabled={show1}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Address
                </Typography>
              </Grid>

              {!userMod ? (
                <>
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {user.address_details?.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {user.address_details?.country}

                      {user.address_details?.state
                        ? " , " + user.address_details?.state
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="left"
                    >
                      {user.address_details?.city
                        ? user.address_details?.city + " , "
                        : ""}
                      {user.address_details?.pincode}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={8}>
                    <GoogleMaps
                      onValueChange={(value) =>
                        onUserAddressValueChanged(
                          "address_details.address",
                          value
                        )
                      }
                      defaultAddress={user?.address_details.address}
                      error={errorMessages?.addressError?.length > 0}
                      helperText={errorMessages?.addressError}
                      show1={show1}
                    />
                  </Grid>
                  <Grid item sm={4}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      openOnFocus={true}
                      id="country"
                      noOptionsText={"No country"}
                      options={
                        countries && countries?.map((country) => country.name)
                      }
                      defaultValue={user?.address_details?.country}
                      onChange={(e, value) =>
                        onUserAddressValueChanged(
                          "address_details.country",
                          value
                        )
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="country"
                          error={errorMessages?.countryError?.length > 0}
                          helperText={errorMessages?.countryError}
                          disabled={show1}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      openOnFocus={true}
                      id="state"
                      options={states}
                      defaultValue={user?.address_details?.state}
                      noOptionsText={"Select country"}
                      onChange={(e, value) =>
                        onUserAddressValueChanged(
                          "address_details.state",
                          value
                        )
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="State"
                          error={errorMessages?.stateError?.length > 0}
                          helperText={errorMessages?.stateError}
                          disabled={show1}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={4}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      openOnFocus={true}
                      id="city"
                      options={cities}
                      defaultValue={user?.address_details?.city}
                      noOptionsText={"Select state"}
                      onChange={(e, value) =>
                        onUserAddressValueChanged("address_details.city", value)
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="city"
                          error={errorMessages?.cityError?.length > 0}
                          helperText={errorMessages?.cityError}
                          disabled={show1}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item xs={12} sm={3}>
                    <CustomInput
                      variant="outlined"
                      label="pincode"
                      defaultValue={user?.address_details?.pincode}
                      onChange={(event) =>
                        onUserAddressValueChanged(
                          "address_details.pincode",
                          event.target.value
                        )
                      }
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      error={errorMessages?.pincodeError?.length > 0}
                      helperText={errorMessages?.pincodeError}
                      disabled={show1}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Referred By
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                {user.ref}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  User Agent Data
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                {result.ua}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Browser Data
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                Browser: {result.browser.name}, Major: {result.browser.major},
                Version: {result.browser.version}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  CPU Data
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                {result.cpu.architecture}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  Engine Data
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                Engine: {result.engine.name}, Version:&nbsp;
                {result.engine.version}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" align="left">
                  OS Data
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                OS: {result.os.name}, Version:&nbsp;
                {result.os.version}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  return {
    loadings: adminReducer.loadings,
    user: adminReducer.user,
    admin: adminReducer.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getUser: (data) => dispatch(actionCreator.getUser(data)),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    updateUserDetails: (data) =>
      dispatch(actionCreator.updateUserDetails(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
